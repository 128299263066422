<!--
  preserveAspectRatio of xMidYMid meet as the center of the viewport is the circle's
  center. The center of the circle will remain at the center of the mat-progress-spinner
  element containing the SVG. `focusable="false"` prevents IE from allowing the user to
  tab into the SVG element.
-->

<svg
  [style.width.px]="diameter"
  [style.height.px]="diameter"
  [attr.viewBox]="_getViewBox()"
  preserveAspectRatio="xMidYMid meet"
  focusable="false"
  [ngSwitch]="mode === 'indeterminate'">

  <!--
    Technically we can reuse the same `circle` element, however Safari has an issue that breaks
    the SVG rendering in determinate mode, after switching between indeterminate and determinate.
    Using a different element avoids the issue. An alternative to this is adding `display: none`
    for a split second and then removing it when switching between modes, but it's hard to know
    for how long to hide the element and it can cause the UI to blink.
  -->
  <circle
    *ngSwitchCase="true"
    cx="50%"
    cy="50%"
    [attr.r]="_getCircleRadius()"
    [style.animation-name]="'mat-progress-spinner-stroke-rotate-' + _spinnerAnimationLabel"
    [style.stroke-dashoffset.px]="_getStrokeDashOffset()"
    [style.stroke-dasharray.px]="_getStrokeCircumference()"
    [style.stroke-width.%]="_getCircleStrokeWidth()"></circle>

  <circle
    *ngSwitchCase="false"
    cx="50%"
    cy="50%"
    [attr.r]="_getCircleRadius()"
    [style.stroke-dashoffset.px]="_getStrokeDashOffset()"
    [style.stroke-dasharray.px]="_getStrokeCircumference()"
    [style.stroke-width.%]="_getCircleStrokeWidth()"></circle>
</svg>
